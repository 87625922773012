<template>

  <div class="dashboard-content">
    <loader v-if="isLoaded"
     object="#3370b7" 
     color1="#ffffff" 
     color2="#17fd3d" 
     size="5"
     speed="2" 
     bg="#ffffff" 
     objectbg="#cfcfcf" 
     opacity="80"
      disableScrolling="false"
       name="spinning"></loader>
    <div class="row">
       <div class="col-lg-6 col-md-12">
        <div class="dashboard-list-box mb-5">
          <div class="dashboard-list-box-static">
            <h4 class="mb-3" style="display:inline">
              Profile Image
            </h4>
            <span class="text-danger font-weight-bold">(Optional)</span>
            <div :key="this.image"> <form>
                <div class="custom-file" style="height:250px"> 
                  <input
                    id="validatedCustomFile"
                    type="file"
                    class="custom-file-input"
                    @change="handleFileUpload()"
                    ref="file"
                    name="image"
                    accept="image/*"
                    required
                  >
                  <label
                    class="custom-file-label"
                    for="validatedCustomFile"
                  >Choose file...</label>
                  <div class="avatar-preview text-center">
                      <img
                        :src="imagePreview(formData)"
                        id="imagePreview"
                        class="img-fluid b-blue img-logo mt-3"
                        style="width:190px;height:190px;border-radius:50%;object-fit:cover;"
                      />
                    </div>
                </div>
                </form>
              </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="dashboard-list-box mb-5">
          <div class="dashboard-list-box-static">
            <h4 class="mb-3">
              Profile Details
            </h4>
            <div class="my-profile">
              <div class="mt-3">
                <div class="form-group" v-if="role == 'sponsor'">
                  <label for="">User Name:</label>
                  <input 
                    type="text"
                    placeholder="Name"
                    class="form-control form-control-alternative"
                    v-model="obj.business_name"
                    autocomplete="off"
                  >
                  
                </div>
                 <div class="form-group" v-else>
                  <label for="">Name:</label>
                  <input
                    type="text"
                    placeholder="Name"
                    class="form-control form-control-alternative"
                    v-model="obj.name"
                    autocomplete="off"
                  >
                </div>
              </div>
              <!-- <div class="form-group">
                <label for="">ZIP Code:</label>
                <input
                  type="email"
                  placeholder="Zip Code"
                  class="form-control form-control-alternative"
                  v-model="obj.zipcode"
                  readonly
                >
              </div> -->
              <div class="form-group">
                <label for="">Email:</label>
                <input
                  type="email"
                  placeholder="email"
                  class="form-control form-control-alternative"
                  v-model="obj.email"
                  readonly
                >
              </div>
                <div class="my-profile">
              <div class="form-group">
                <label for="">Current Password:</label>
                <input
                  type="password"
                  placeholder="Current Password"
                  class="form-control form-control-alternative"
                  v-model="obj.password"
                  autocomplete="off"

                >
              </div>
              <div class="form-group">
                <label for="">New Password:</label>
                <input
                  type="password"
                  placeholder="New Password"
                  class="form-control form-control-alternative"
                    v-model="obj.new_password"
                    autocomplete="off"
                >
              </div>
              <div class="form-group">
                <label for="">Retype New Password:</label>
                <input
                  type="password"
                  placeholder="Retype New Password"
                  class="form-control form-control-alternative"
                    v-model="obj.confirm_password"
                    autocomplete="off"
                >
              </div>
            </div>
            </div>
            <button @click="saveChanges" type="button" class="btn btn-primary">
              Save Changes
            </button>
          </div>
        </div>
      </div>
      <!-- Copyrights -->
      <div class="col-md-12">
        <div class="copyrights">
          © 2021 Thriggle. All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {axios} from '../../services/http-common';
export default {
  data(){
    return {
      isLoaded:false,
      formData:'',
      image: localStorage.getItem('image'),
      role: localStorage.getItem('role'),
      obj:'',
      errorsData:''
    }
  },
 
  methods:{
    handleFileUpload(){
      let formData = new FormData();
      formData.append("image", this.$refs.file.files[0]);

        this.isLoaded = true;
        axios
        .post(process.env.VUE_APP_API_URL + "upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
        })
        .then(function(res) {
          localStorage.setItem('image',res.data.success);
          location.reload();
            this.isLoaded = false;
        })
        .catch(function(error) {
            this.isLoaded = false;
        });
    },
     imagePreview(data) {
      let image = localStorage.getItem('image');
        let role = localStorage.getItem('role');
      if (image && image != "null") {
        if (image.indexOf("https://") > -1) {
          return image;
        } else {
           if(role == 'sponsor'){
            return process.env.VUE_APP_BASE_URL + "ads/" + image;
          }else{
            return process.env.VUE_APP_BASE_URL + role+ "s/" + image;
          }
        }
      } else {
        return "/static/images/man.png";
      }
    },
    saveChanges(){
        var id = localStorage.getItem('user_id');
         let role = localStorage.getItem('role');
        var self = this;
          axios
        .patch(process.env.VUE_APP_API_URL + role + "s/"+id, this.obj, {
          
        })
        .then(res => {
          if(res.data.data.updatedToken)
          localStorage.setItem('token',res.data.data.updatedToken.token);
          	self.$toast.success('Profile Updated', {
                  position: "bottom-right",
                  timeout: 2508,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                  top:'300px',
                });
          location.reload();
        })
        .catch(error =>{
          let errors = error.response.data.errors;
          for (let field of Object.keys(errors)) {
              // this.errorsData(errors[field][0], 'error');
              	self.$toast.error(errors[field][0], {
                  position: "bottom-right",
                  timeout: 2508,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                  top:'300px',
                });
          }
        });
        // console.log('final errors are:',this.errorsData);
    }
  },
  mounted(){
    let id = localStorage.getItem('user_id');
    let role = localStorage.getItem('role');
      axios
        .get(`${process.env.VUE_APP_API_URL}${role}s/${id}`)
        .then(response => {
            if (!response.error) {
             this.obj  = response.data.data;
            }
        })
        .catch( error => {
            
        });
  }

};
</script>
